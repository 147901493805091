import React, { useRef } from "react"
import {
  FormControl,
  Input,
  Box,
  Typography,
  Icon,
  TextField,
} from "@mui/material"
import Button from "components/material-dashboard-pro-react/components/CustomButtons/Button"
import FormatPhoneLocal from "components/Core/NumberFormat/FormatPhoneLocal"
import Axios from "axios"

import { useDialog } from "context/DialogContext"
import { useForm } from "react-hook-form"
import BudgetApplicant from "../../../../Portal/Views/Budget/BudgetApplicant"
import { DevTool } from "@hookform/devtools"

const styleFormControl = {
  width: "100%", // Ajusta el ancho al 100% del contenedor
  padding: "0px 10px",
}

const defaultValues = {
  p_applicant_name: "",
  p_applicant_phone_number: "",
  p_applicant_email: "",
}

export const FormularioCotizar = ({ panelPaginaGenerales }) => {
  const codigoProducto = panelPaginaGenerales?.length
    ? panelPaginaGenerales[0].node.nombre_producto
    : []
  console.log("var valor", codigoProducto)
  const dialog = useDialog()
  const methods = useForm({ defaultValues })
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = methods

  async function onSubmit(dataform, e) {
    try {
      const params = {
        p_json_info: JSON.stringify({
          TIPOID: null,
          CEDULA: null,
          NOMBRE: dataform.p_applicant_name,
          EDAD: null,
          // edad: edadRef.current.value,
          TELEFONO: dataform.p_applicant_phone_number,
          CELULAR: dataform.p_applicant_phone_number,
          EMAIL: dataform.p_applicant_email,
          CANAL_VENTA: "P",
          PRODUCTO: codigoProducto,
          OBSERVACION: null,
          ESTATUS: null,
          IDEPOL: null,
          NUMCERT: null,
        }),
        p_portal_user_id: null,
      }
      const response = await Axios.post(
        "/dbo/crm_whatsapp/reg_crm_contact_requests",
        params
      )
      if (response.data.p_observ === "OK") {
        reset()

        dialog({
          variant: "info",
          title: "Notificación",
          description: "Su solicitud se registró con éxito próximamente lo estaremos contactando",
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px", // Espacio entre los campos
        background: "#f5f5f5",
        padding: "1rem",
        width: "320px", // Ancho fijo
        minHeight: "300px", // Altura fija
        maxWidth: "100%", // Asegura que no se desborde en pantallas pequeñas
        margin: "auto", // Centra el formulario
        justifyContent: "space-between", // Distribuye espacio entre los elementos
      }}
    >
      {/* Título del formulario y subtítulo en el mismo Typography */}
      <Typography
        variant="h6"
        component="h2"
        sx={{
          textAlign: "center",
          color: "#fc2d23",
          fontSize: "0.9rem",
          margin: "0",
          lineHeight: "1.5rem", // Espaciado entre líneas
          borderBottom: "1px solid red",
        }}
      >
        Cotiza tu póliza de forma rápida y sencilla
        <br />
        <span style={{ fontWeight: "bold", fontSize: "0.8rem" }}>Personas</span>
      </Typography>
      <DevTool control={control} />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <BudgetApplicant control={control} />
        <Button color="primary" type="submit" fullWidth sx={{ mt: 1 }}>
          <Icon>send</Icon> Contáctanos
        </Button>
      </form>
    </Box>
  )
}
