import React, { useState, useEffect } from "react";
import '../css/ButtonUpFloat.css';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import Tooltip from "@mui/material/Tooltip"
import Zoom from "@mui/material/Zoom"

export const ButtonUpFLoat = () => {
  const [isVisible, setIsVisible] = useState(false);
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    if (scrollTop > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Desplazamiento suave
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Tooltip
      title={'Ir al Inicio'}
      placement="right-start"
      arrow
      disableHoverListener={!isVisible}
      hidden={!isVisible}
      TransitionComponent={Zoom}
    >
      <button color="primary"
        onClick={scrollToTop}
        disabled={!isVisible}
        className={`button-up-float ${isVisible ? "fade-in" : "fade-out"}`}
        style={{
          position: 'fixed',
          width: '30px',
          height: '30px',
          bottom: '30px',
          right: '45px',
          zIndex: '9999',
          borderRadius: '50%',
          background: '#7c7b7b',
          color: 'white',
          cursor: isVisible ? 'pointer' : 'default',
          border: 'solid 2px #ffffff',
          boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
          padding: "1px"
        }}>

        <KeyboardDoubleArrowUpIcon />
        
      </button>
    </Tooltip>

  )
}
