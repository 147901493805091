// import React from 'react';
// import { styled } from '@mui/material';
// import { CleanText } from '../Functions/CleanText';
// import { H2 } from '../constants/constants';
// import { COLOR_FONDO, TAMANIO_IMG_PANEL_INFORMATIVO } from '../constants/constants';

// const Div = styled('div')(({ isNormal }) => ({
//     display: 'flex',
//     marginTop: '2rem',
//     marginBottom: '2rem',
//     flexDirection: isNormal ? 'row' : 'row-reverse',
//     justifyContent: 'space-around',
//     gap: '100px',
//     padding: '20px 10rem',
//     // alignItems: 'flex-start',  // Cambiamos a 'stretch' para que flex-items ocupen todo el espacio verticalmente
//     img:{ 
//         width: '100%', 
//         maxWidth: TAMANIO_IMG_PANEL_INFORMATIVO.width, 
//         minHeight: TAMANIO_IMG_PANEL_INFORMATIVO.height,
//     },

//     // Alinear la imagen en el centro si el artículo es más grande
//     '@media (min-width: 801px)': {
//         // padding: '0px 10rem',
//         alignItems: 'start', // Alinear al inicio por defecto
//         '& img': {
//             alignSelf: 'center', // Centrar la imagen si el artículo es más grande
//         },
//         '& article': {
//             alignSelf: 'flex-start', // Al inicio cuando el article es más pequeño
//         }
//     },



//     '@media (max-width: 1024) ':{
//         padding: '40px',
//         alignItems: 'start', // Alinear al inicio por defecto
//     },

//     "@media (max-width: 800px)": {
//         marginTop:'0px',
//         display: 'grid', // Cambia a grid en pantallas pequeñas
//         gridTemplateColumns: '1fr', // Una columna
//         gridTemplateRows: 'auto auto', // Dos filas: una para la imagen, otra para el artículo
//         gap: '20px', // Espacio entre la imagen y el artículo
//         padding: '40px',
//         fontSize: '1rem',
//         img:{
//             maxWidth: '100%',
//         }
//     },

//     "@media (max-width: 600px)": {
//         marginTop:'0px',
//         display: 'grid', // Cambia a grid en pantallas pequeñas
//         gridTemplateColumns: '1fr', // Una columna
//         gridTemplateRows: 'auto auto', // Dos filas: una para la imagen, otra para el artículo
//         gap: '20px', // Espacio entre la imagen y el artículo
//         padding: '40px',
//         fontSize: '1rem',
//         img:{
//             justifySelf:'center',
//             alignSelf:'center',
//             maxWidth: '280px', 
//             minHeight: '280px',
//         }
//     },
// }));

// const Article = styled('article')({
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'flex-start',
//     gap: '30px',
//     H2: {
//         margin: '0',
//         fontWeight:'bold',
//         fontSize:'1.7rem',
//         textAlign:'start'
//     },
//     p: {
//         fontSize: '14px',
//         textAlign:"justify",
//         margin: '0 0 0 30px',
//     },
//     "@media (max-width: 768px)": {
//         width: '100%', // Ocupar todo el ancho en pantallas pequeñas
//         H2: {
//             margin: '0',
//             fontSize: '1.2rem',
//         },
//         p: {
//             fontSize: '1rem',
//             margin: '0',
//         },
//     },

//     "@media (max-width: 600px)": {
//         width: '100%', // Ocupar todo el ancho en pantallas pequeñas
//         marginTop: '1rem',
//         H2: {
//             margin: '0',
//             fontSize: '1rem',
//         },
//         p: {
//             fontSize: '1rem',
//             margin: '0',
//         },
//     },
// });

// export const PanelInformativo = ({ panelInfomativoData , backg}) => {
//     const { imagen, posicion_imagen, textos } = panelInfomativoData;

//     return (
//         <Div isNormal={posicion_imagen} style={{background:backg && COLOR_FONDO.color}}>
//             {/* Imagen con tamaño adaptable */}
//             <img 
//                 src={imagen} 
//                 alt={`img about ${textos.length >0 ? textos[0].titulo : 'seguro'}`}  
//             />
//             {/* Article que se adapta al tamaño disponible */}
//             <Article>
//                 {textos.map((texto, i) => {
//                     return (
//                         <div key={i} style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
//                             <H2>{texto.titulo}</H2>
//                             <p>{CleanText(texto.parrafo)}</p>
//                         </div>
//                     );
//                 })}
//             </Article>
//         </Div>
//     );
// }

import React from 'react';
import { styled } from '@mui/material';
import { CleanText } from '../Functions/CleanText';
import { H2 } from '../constants/constants';
import { COLOR_FONDO, TAMANIO_IMG_PANEL_INFORMATIVO } from '../constants/constants';

const Div = styled('div')(({ isNormal }) => ({
    display: 'flex',
    marginTop: '2rem',
    marginBottom: '2rem',
    flexDirection: isNormal ? 'row' : 'row-reverse',
    justifyContent: 'space-around',
    gap: '100px',
    padding: '20px 10rem', // Padding por defecto para pantallas grandes
    img: { 
        width: '100%', 
        maxWidth: TAMANIO_IMG_PANEL_INFORMATIVO.width, 
        maxHeight: TAMANIO_IMG_PANEL_INFORMATIVO.height,
        alignSelf: 'center',
        objectFit: "contain"
    },

    // Pantallas medianas: 1024px o menos
    '@media (max-width: 1024px)': {
        padding: '40px',  // Ajuste de padding para pantallas de hasta 1024px
        alignItems: 'start',  // Alinear al inicio en pantallas medianas
        marginBottom:0,
        marginTop:0,
        img: {
            alignSelf: 'center',  // Centrar la imagen si es más grande que el contenido
        },
    },

    // Pantallas pequeñas: 800px o menos
    '@media (max-width: 800px)': {
        display: 'grid',  // Cambiar a grid en pantallas pequeñas
        gridTemplateColumns: '1fr', // Una columna
        gridTemplateRows: 'auto auto', // Dos filas: una para la imagen y otra para el artículo
        gap: '20px', // Espacio entre la imagen y el artículo
        padding: '40px',
        fontSize: '1rem',
        img: {
            maxWidth: '300px',  // Ajustar la imagen al ancho total en pantallas pequeñas
            maxHeight:'300px',
            alignSelf:'center',
            margin: '0 auto',
        }
    },

    // Pantallas móviles pequeñas: 600px o menos
    "@media (max-width: 600px)": {
        display: 'grid',  // Cambiar a grid en pantallas pequeñas
        gridTemplateColumns: '1fr',  // Una columna
        gridTemplateRows: 'auto auto',  // Dos filas: imagen y artículo
        gap: '20px',  // Espacio entre la imagen y el artículo
        padding: '20px 40px',  // Mantener el padding en pantallas pequeñas
        fontSize: '1rem',
        img: {
            justifySelf: 'center',
            alignSelf: 'center',
            maxWidth: '280px',  // Ajuste de imagen para pantallas muy pequeñas
            minHeight: '200px',
        }
    },
}));

const Article = styled('article')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '30px',
    H2: {
        margin: '0',
        fontWeight: 'bold',
        fontSize: '1rem',
        textAlign: 'start',
    },
    p: {
        fontSize: '13px',
        textAlign: "justify",
        margin: '0 0 0 30px',
    },
    "@media (max-width: 768px)": {  // Ajustes para pantallas de hasta 768px (tablets pequeñas)
        width: '100%',
        H2: {
            margin: '0',
            fontSize: '1.2rem',
        },
        p: {
            fontSize: '1rem',
            margin: '0',
        },
    },

    "@media (max-width: 600px)": {  // Ajustes para pantallas pequeñas (móviles)
        width: '100%',
        marginTop: '1rem',
        H2: {
            margin: '0',
            fontSize: '1rem',
        },
        p: {
            fontSize: '1rem',
            margin: '0',
        },
    },
});

export const PanelInformativo = ({ panelInfomativoData, backg }) => {
    const { imagen, posicion_imagen, textos } = panelInfomativoData;
    const altText = 'La recomendación más extendida en los manuales de estilo y en los tratados sobre escritura es la brevedad, que se cuantifica sobre las 20 o 30 palabras por frase en la mayoría de los casos. Esta recomendación es de carácter general y no afecta, por supuesto, a la expresión literaria. Quedan también fuera de ella las consideraciones referidas al estilo personal de cada escritor, puesto que sebasa en criterios funcionales y no estéticos.'
    return (
        <Div isNormal={posicion_imagen} style={{ background: backg && COLOR_FONDO.color }}>
            {/* Imagen con tamaño adaptable */}
            <img 
                src={imagen} 
                alt={`img about ${textos.length > 0 ? textos[0].titulo : 'seguro'}`}  
            />
            {/* Article que se adapta al tamaño disponible */}
            <Article>
                {textos.map((texto, i) => {
                    return (
                        <div key={i} style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                            <H2>{texto.titulo}</H2>
                            <p>{CleanText(texto.parrafo)}</p>
                        </div>
                    );
                })}
            </Article>
        </Div>
    );
};


