import React from "react"
import { styled } from '@mui/material'
import { FormularioCotizar } from "./FormularioCotizar";
import { ButtonCotizar } from "./ButtonCotizar";

const SectionPrincipal = styled("section")(({ imagenUrl }) => ({
    backgroundImage: imagenUrl ? `url(${imagenUrl})` : "none", // Verifica si hay imagen, de lo contrario, no aplica nada
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "#ffa400",  // Color de fondo en caso de que no haya imagen
    display: "flex",
    color: "white",
    padding: "3rem",
    justifyContent: "end", // Empuja el contenido a los extremos
    alignItems: "center",
    gap: "10px",
    position:'relative',
    zIndex:'100',
    paddingRight:"100px",
    "@media (max-width: 768px)": {
        flexWrap: "wrap",
        textAlign: "center",
        padding: "20px",
        justifyContent: "end",
        alignItems: "center",
        paddingRight:"50px",
        fontSize: "1em",
    },
    "@media (max-width: 600px)": {
        flexWrap: "wrap",
        textAlign: "center",
        padding: "20px",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1em",
    },
}));


const CotizadorForm = styled("div")({
    display: "flex",
    justifyContent: "center",
    flexShrink: "0",
});

const Title = styled("h2")({
    "@media (max-width: 1024px)": {
        flexWrap: "wrap",
        textAlign: "center",
        padding: "20px",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1.8rem",
    },
    "@media (max-width: 768px)": {
        flexWrap: "wrap",
        textAlign: "center",
        padding: "20px",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1.5rem",
    },
    "@media (max-width: 600px)": {
        flexWrap: "wrap",
        textAlign: "center",
        padding: "20px",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1.6rem",
        
    },
});

export const Contactanos = ({ panelContactanos }) => {

    const { texto = "" } = panelContactanos[0]?.node || {};
    const imagenUrl = panelContactanos[0].node.imagen.localFile.url;

    // recordar de quitar el let por const para los casos de prueba
    const {
        banner_boton_accion = false,
        banner_formulario = false,
        banner_generico = false,
    } = panelContactanos[0]?.node || {};

    const RenderBanner = () => {
        if (banner_formulario) {
            return (
                <CotizadorForm>
                    <FormularioCotizar />
                </CotizadorForm>
            );
        }

        if (banner_boton_accion) {
            return (
                <CotizadorForm>
                    <ButtonCotizar />
                </CotizadorForm>
            );
        }
        return null;
    };

    return (
        <SectionPrincipal imagenUrl={imagenUrl}>
            <article>
                <Title>{texto}</Title>
            </article>
            {/* Acá hacemos la comparación si queremos traer un formulario o solamente un botón */}
            {RenderBanner()}
        </SectionPrincipal>
    );
};
