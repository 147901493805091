import React from 'react'

//mui components
import { styled } from "@mui/material/styles"

//Core components
import LandingPage from '../../Layout/LandingPage'
import SectionPrincipal from '../Sections/SectionPrincipal'
import SectionCaracteristicas from '../Sections/SectionCaracteristicas'
import SectionProductos from '../Sections/SectionProductos'
import SectionNoticias from '../Sections/SectionNoticias'
import SectionLocations from '../Sections/SectionLocations';
import presentationStyle from "./presentationStyle.js";
import PopUp from '../../Layout/Popup';
import PopUpAsesores from '../../Layout/PopUpAsesores.js'
import PopupadultooceanicaDesktop from '../../../../static/PopupadultooceanicaDesktop.jpg'
import PopupadultooceanicaMobile from '../../../../static/PopupadultooceanicaMobile.jpg'

const company = process.env.GATSBY_INSURANCE_COMPANY


const NewDiv = styled("div")(({ theme }) => ({
    ...presentationStyle.main,
    ...presentationStyle.mainRaised
  }))

export default function PresentationPage() {

    React.useEffect(() => {
        document.body.scrollTop = 0;
    });
    return (
        <LandingPage noChatBot={true} pageTitle="Empresa de seguros en Venezuela" >
            {company === "OCEANICA" ? (
                <PopUpAsesores
                    company={company}
                    popupOceanica={PopupadultooceanicaMobile}
                    popupOceanicaDesktop={PopupadultooceanicaDesktop}
                    flyerOceanica="flyerRecaudosFacturacionalCobro.jpeg"
                    callToActionMessage=""
                />
           ) : null }  
           
            
            <SectionPrincipal/>
            <NewDiv>
                <SectionCaracteristicas/>
                <SectionProductos/>
                <SectionNoticias/>
                <SectionLocations/> 
            </NewDiv>
        </LandingPage>
    )
}
