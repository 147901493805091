import React from 'react';
import { styled } from '@mui/material';
import { H2 } from '../constants/constants';
import { SeparateParagraphs } from '../Functions/SeparateParagraphs';

const Section = styled('section')({
    display: 'flex',
    flexDirection:'column',
    marginTop: '2rem',
    marginBottom: '2rem',
    padding: '0 10rem', // Padding por defecto para pantallas grandes
    // Pantallas medianas: 1024px o menos
    H2:{
        alignSelf:'center'
    },
    '@media (max-width: 1024px)': {
        padding: '0 40px',  // Ajuste de padding para pantallas de hasta 1024px
        alignItems: 'start',  // Alinear al inicio en pantallas medianas
        marginBottom:0,
        marginTop:0,
        img: {
            alignSelf: 'center',  // Centrar la imagen si es más grande que el contenido
        },
    },

    // Pantallas pequeñas: 800px o menos
    '@media (max-width: 800px)': {
        display: 'grid',  // Cambiar a grid en pantallas pequeñas
        gridTemplateColumns: '1fr', // Una columna
        gridTemplateRows: 'auto auto', // Dos filas: una para la imagen y otra para el artículo
        gap: '20px', // Espacio entre la imagen y el artículo
        fontSize: '1rem',
        img: {
            width: '300px',  // Ajustar la imagen al ancho total en pantallas pequeñas
            height:'200px',
            alignSelf:'center',
            margin: '0 auto'
        }
    },

    // Pantallas móviles pequeñas: 600px o menos
    "@media (max-width: 600px)": {
        display: 'grid',  // Cambiar a grid en pantallas pequeñas
        gridTemplateColumns: '1fr',  // Una columna
        gridTemplateRows: 'auto auto',  // Dos filas: imagen y artículo
        gap: '20px',  // Espacio entre la imagen y el artículo
        padding: '20px 40px',  // Mantener el padding en pantallas pequeñas
        fontSize: '1rem',
        img: {
            justifySelf: 'center',
            alignSelf: 'center',
            maxWidth: '280px',  // Ajuste de imagen para pantallas muy pequeñas
            minHeight: '200px',
        }
    },
});

const Article = styled('article')({
    p:{
        textAlign: 'justify'
    } 
})

export const PanelDescriptivo = ( {seccionDescriptivaPagina} ) => {
    const {nombre_producto, parrafo} =seccionDescriptivaPagina[0]?.node
    const cleanParagraphs = SeparateParagraphs(parrafo.data.parrafo)
    
    return (
        <Section>
            <H2 style={{textAlign:'center'}}>{nombre_producto}</H2>
            <Article>
                {cleanParagraphs.map((paragraph,i)=>{
                    return <p key={i}>{paragraph}</p>
                })}
            </Article>
        </Section>
    );
};


