import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { H2 } from '../constants/constants';
import { IMG_SECTION_BOTON_LATERAL } from '../constants/constants';
import { styled } from '@mui/material';

const Section = styled('section')({
  padding: '0 10rem', // Padding por defecto para pantallas grandes
  // Pantallas medianas: 1024px o menos
  H2: {
    textAlign: 'center',
    marginBottom: '16px',
  },
  '@media (max-width: 1024px)': {
    padding: '0 40px',  // Ajuste de padding para pantallas de hasta 1024px
  },

  // Pantallas pequeñas: 800px o menos
  '@media (max-width: 800px)': {
    padding: '0 40px',
  },
  // Pantallas móviles pequeñas: 600px o menos
  "@media (max-width: 600px)": {
    padding: '20px 40px',

  },
});

const ContainerSection = styled('section')({
  display: 'grid',
  gridTemplateColumns: '200px 1fr',
  gap: '20px',
  '@media (max-width: 1024px)': {
    // padding: '40px',  // Ajuste de padding para pantallas de hasta 1024px
    // alignItems: 'start',  // Alinear al inicio en pantallas medianas
    // marginBottom:0,
    // marginTop:0,
  },

  // Pantallas pequeñas: 800px o menos
  '@media (max-width: 800px)': {
    // display: 'grid',  // Cambiar a grid en pantallas pequeñas
    gridTemplateColumns: '1fr', // Una columna
    // gridTemplateRows: 'auto auto', // Dos filas: una para la imagen y otra para el artículo
    // gap: '20px', // Espacio entre la imagen y el artículo
    // padding: '40px',
    // fontSize: '1rem',
  },
  // Pantallas móviles pequeñas: 600px o menos
  "@media (max-width: 600px)": {
    // display: 'grid',  // Cambiar a grid en pantallas pequeñas
    gridTemplateColumns: '1fr',  // Una columna
    // gridTemplateRows: 'auto auto',  // Dos filas: imagen y artículo
    // gap: '20px',  // Espacio entre la imagen y el artículo
    // padding: '20px 40px',  // Mantener el padding en pantallas pequeñas
    // fontSize: '1rem',
  },
});

const StyleBoton = styled('button')({
  background: '#ffa400',
  color: 'white',
  borderRadius: '20px',
  padding: '10px 20px',
  border: 'none',
  cursor: 'pointer',
  '&:hover':
  {
    background: '#e59300'
  }
})

const CajaBotones = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  justifyContent: 'flex-start',
  marginTop: "23px",
  '@media (max-width: 800px)': {
    flexDirection: 'row',
    flexwrap: 'wrap'
  },
  "@media (max-width: 600px)": {
    display: 'grid',  // Cambiar a grid en pantallas pequeñas
    gridTemplateColumns: '1fr',
  },
})

const TextBox = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  p: {
    textAlign: 'justify'
  },
  '@media (max-width: 800px)': {
    flexwrap: 'wrap'
  },
})

const ContainerTextBox = styled('div')({
  display: 'grid',
  gridTemplateColumns: "250px 1fr",
  gap: '20px',
  '@media (max-width: 800px)': {
    // flexwrap: 'wrap'
  },
  "@media (max-width: 600px)": {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
})

const PieDePaginaBtns = styled('div')({
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px',
  "@media (max-width: 767px)": {
    justifyContent: "center",
  }
})

const LoadingBox = styled('div')({
  height: IMG_SECTION_BOTON_LATERAL.height,
  width: IMG_SECTION_BOTON_LATERAL.width,
  background: "#d5d2d2",
  color: "#333",
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  p: {
    color: '#ed1c24',
    fontWeight: 'bold'
  }
})

export const SectionBotonLateral = ({ seccionLateral, seccionDescriptivaPagina }) => {
  const [seccion, setSeccion] = useState(seccionLateral[0]);
  const [loading, setLoading] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [loadedImages, setLoadedImages] = useState(new Set());

  console.log(seccionDescriptivaPagina)

  useEffect(() => {
    const determineImageType = () => {
      if (window.innerWidth < 600) {
        return 'mobile';
      } else if (window.innerWidth < 769) {
        return 'tablet';
      } else {
        return 'desktop';
      }
    };

    const handleResize = () => {
      const deviceType = determineImageType();
      setBackgroundImage(deviceType);
    };

    const initialDeviceType = determineImageType();
    setBackgroundImage(initialDeviceType);
    setImageUrl(seccionLateral[0].relationatedText[`imagen_${initialDeviceType}`]);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [seccionLateral]);

  useEffect(() => {
    if (seccion) {
      const newImageUrl = seccion.relationatedText[`imagen_${backgroundImage}`];
      setImageUrl(newImageUrl);
      // Verificar si la imagen ya está cargada
      if (loadedImages.has(newImageUrl)) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
  }, [seccion, backgroundImage, loadedImages]);

  const handleClick = (i) => {
    const nuevaSeccion = seccionLateral[i];
    const nuevaImagenUrl = nuevaSeccion.relationatedText[`imagen_${backgroundImage}`];

    setSeccion(nuevaSeccion);

    if (loadedImages.has(nuevaImagenUrl)) {
      setLoading(false);
    } else {
      setLoading(true);
    }
    setImageUrl(nuevaImagenUrl);
  };

  const handleImageLoad = () => {
    setLoading(false);
    setLoadedImages((prevLoadedImages) => new Set(prevLoadedImages).add(imageUrl));
  };

  return (
    <Section>
      <H2>{seccionDescriptivaPagina[0]?.node?.titulo_seccion_descriptiva}</H2>
      <ContainerSection>
        <CajaBotones>
          {seccionLateral.map((boton, i) => (
            <StyleBoton key={i} onClick={() => handleClick(i)}>
              {boton.nombre_boton}
            </StyleBoton>
          ))}
        </CajaBotones>
        {seccion && (
          <ContainerTextBox>
            {loading && (
              <LoadingBox>
                <p>Cargando...</p>
                <CircularProgress style={{ marginTop: '10px' }} />
              </LoadingBox>
            )}
            <img
              src={imageUrl}
              style={{ display: loading ? "none" : "block" }}
              width={IMG_SECTION_BOTON_LATERAL.height}
              height={IMG_SECTION_BOTON_LATERAL.height}
              onLoad={handleImageLoad}
              alt="Descripción de la imagen"
            />
            <TextBox style={{ justifyContent: "space-between" }}>
              <p style={{ marginTop: "23px" }}>{seccion.relationatedText.parrafo}</p>
              <PieDePaginaBtns>
                {seccion.pieDePagina.map((btn, i) => (
                  <StyleBoton
                    onClick={() => window.location.assign(btn.url)}                    
                    key={i}
                  >
                    {btn.nombre_boton}
                  </StyleBoton>
                ))}
              </PieDePaginaBtns>
            </TextBox>
          </ContainerTextBox>
        )}
      </ContainerSection>
    </Section>
  );
};
