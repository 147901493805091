import { styled } from "@mui/material"

export const H2 = styled('h2')({
    fontWeight:'bold',
    textAlign:'center',
    "@media (max-width: 999px)": {
        fontSize:'1.7rem'
    },
    "@media (min-width: 1000px) and (max-width: 1600px)": {
        fontSize:'1.8rem'
    },
    "@media (min-width: 1601px) ": {
        fontSize:'2rem'
    },
})

export const IMG_SECTION_BOTON_LATERAL ={
    width: '230px',
    height: '230px'
}

export const COLOR_FONDO = {
    color: '#f9f9f9'
}

export const TAMANIO_IMG_PANEL_INFORMATIVO ={
    width: '300px',
    height: '200px'
}
