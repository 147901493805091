import React, { useEffect, useRef, useState } from "react"
import Carousel from "react-material-ui-carousel"
import { CardCarousel } from "./CardCarousel"
import { styled, useMediaQuery, useTheme } from "@mui/material"
import { CleanText } from "../Functions/CleanText"
import { H2 } from "../constants/constants"
import { COLOR_FONDO } from "../constants/constants"

// Estilos para las flechas de navegación
const styleNavButtonsProps = {
  style: {
    backgroundColor: "#ffa400", // Color de las flechas
    color: "white",
  },
}

const styleIndicatorContainerProps = {
  style: {
    marginTop: "20px", // Separación entre el contenido y los puntos
  },
}

const DivStyled = styled("div")({
  padding: "10px 8rem",
  H2: {
    margin: 0,
  },
  "@media (max-width: 1024px)": {
    padding: "10px 30px",
  },
})

export function PanelBeneficios({ panelBeneficios, backg }) {
  const tituloPanel = panelBeneficios[0].node.titulo
  const copyListOfBenefits = structuredClone(panelBeneficios)
  const orderedList = copyListOfBenefits.sort(
    (a, b) => a.node.orden - b.node.orden
  )

  // const alternativeText = 'El párrafo generalmente lo definimos como un conjunto de oraciones escritas que comparten un tema y un contexto comunicativo, por lo que las escribimos en un orden, es decir, en secuencia. El párrafo también es considerado una de las unidades en que se puede dividir un texto escrito.';
  // orderedList[4].node.parrafo.data.parrafo = orderedList[4].node.parrafo.data.parrafo.concat(alternativeText);

  const theme = useTheme()

  // Determine los puntos de corte para el número de elementos que se muestran
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up("lg")) // >1200px
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg")) // 900px-1200px
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md")) // 600px-900px
  const is1440pxOrLarger = useMediaQuery("(min-width:1440px)")
  // Determinar cuántos elementos se deben mostrar en función del tamaño de la pantalla
  const itemsPerGroup = isExtraLargeScreen
    ? 4
    : isLargeScreen
    ? 3
    : isMediumScreen
    ? 2
    : 1

  // Agrupar los datos en función de cuántos elementos deben estar visibles
  const groupedData = orderedList.reduce((acc, item, index) => {
    const groupIndex = Math.floor(index / itemsPerGroup)
    if (!acc[groupIndex]) acc[groupIndex] = []
    acc[groupIndex].push(item)
    return acc
  }, [])

  // Ocultar los botones de navegación si el número de elementos es menor o igual al número permitido
  const showNavButtons = orderedList.length > itemsPerGroup

  // Estado para almacenar la altura máxima de las tarjetas
  const [maxHeight, setMaxHeight] = useState(0)
  const cardRefs = useRef([]) // Referencia para todas las tarjetas

  // Efecto para calcular la altura máxima de las tarjetas (solo se ejecuta una vez al montar el componente)
  useEffect(() => {
    // Calcular la altura máxima de todas las tarjetas una sola vez
    let maxHeightTemp = 0
    cardRefs.current.forEach((card) => {
      if (card) {
        maxHeightTemp = Math.max(maxHeightTemp, card.offsetHeight)
      }
    })
    setMaxHeight(maxHeightTemp) // Guardar la altura máxima en el estado
  }, []) // Dependencia vacía: solo se ejecuta al montar el componente

  return (
    <DivStyled style={{ background: backg && COLOR_FONDO.color }}>
      <H2>{tituloPanel}</H2>
      <Carousel
        navButtonsAlwaysVisible={showNavButtons} // Muestra los botones solo si hay más elementos que el tamaño permitido
        sx={{
          width: "100%",
          maxWidth: "100%",
          margin: "0 auto",
          overflow: "hidden",
          padding: "30px",
          position: "relative",
        }}
        navButtonsProps={styleNavButtonsProps}
        navButtonsWrapperProps={{
          style: {
            display: !showNavButtons ? "none" : "block",
            top: "50%", // Posición de las flechas
            transform: "translateY(-50%)",
            position: "absolute",
          },
        }}
        indicators={showNavButtons}
        indicatorContainerProps={styleIndicatorContainerProps}
        timeout={500}
        duration={500}
        interval={5000}
        animation="slide"
        autoPlay={false}
        stopAutoPlayOnHover={true}
      >
        {groupedData.map((group, i) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "flex-start",
              width: "100%",
              maxWidth: "100%",
              height: `${maxHeight}px`,
            }}
            key={i}
          >
            {group.map((item, index) => {
              const itemNew = {
                title: item.node?.titulo_principal || "Título no disponible",
                paragraph: CleanText(
                  item.node?.parrafo?.data?.parrafo ||
                    "Descripción no disponible"
                ),
                imgUrl:
                  item.node?.imagen?.localFile?.url || "URL no disponible",
              }

              return (
                <div
                  key={item.node.strapi_id}
                  ref={(el) => (cardRefs.current[index] = el)} // Referencia de la tarjeta
                >
                  <CardCarousel data={itemNew} />
                </div>
              )
            })}
          </div>
        ))}
      </Carousel>
    </DivStyled>
  )
}
