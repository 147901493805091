import React from "react"
import { graphql } from "gatsby"
import { styled } from "@mui/material/styles"
import LandingPage from "../../Layout/LandingPage"
import sectionStyle from "LandingPageMaterial/Views/Sections/sectionStyle"
import { PreguntasFrecuentes } from "./Components/PreguntasFrecuentes"
import { PanelBeneficios } from "./Components/PanelBeneficios"
import { PanelBanner } from "./Components/PanelBanner"
import { Contactanos } from './Components/Contactanos'
import { PanelInformativo } from "./Components/PanelInformativo"
import { ButtonUpFLoat } from "./Components/ButtonUpFLoat"


const NewDiv = styled("div")(({ theme, ownerState }) => {
  const { style } = ownerState
  return { ...sectionStyle[style] }
})

const NewH2 = styled("h2")(({ theme, className }) => ({
  ...className,
}))

const gridItemAutoClasses = {
  ...sectionStyle.mlAuto,
  ...sectionStyle.mrAuto,
}

export default ({ data }) => {

   console.log('ver valor importante' , data)

  const panelPaginaGenerales = data.allStrapiPaginaGenerales.edges


  const panelBanners = data.allStrapiPanelBanners.edges
  const panelBeneficios = data.allStrapiPanelBeneficios.edges
  const panelInformativoImagenes = data.allStrapiPanelInformativoImagenes.edges
  const panelInformativoTextos = data.allStrapiPanelInformativoTextos.edges

  const newArrayPanelInformativo = panelInformativoImagenes.map(element => {

    const relationatedTexts = panelInformativoTextos.filter(text => {
      return text.node?.codigo_panel_informativo_imagen.codigo_panel_informativo === element.node?.codigo_panel_informativo
    })

    const newElement = {
      codigo_panel_informativo: element.node?.codigo_panel_informativo || '',
      imagen: element.node?.imagen.localFile.url || '',
      posicion_imagen: element.node?.posicion_imagen,
      nombre_producto: element.node?.nombre_producto || '',
      orden: element.node?.orden || '',
      textos: relationatedTexts.map(text => {
        return ({
          titulo: text?.node?.texto || '',
          parrafo: text?.node?.parrafo?.data?.parrafo || ''
        })
      })
    }
    return (newElement)
  })

  const panelPreguntaFrecuentes = data.allStrapiPanelPreguntaFrecuentes.edges
  const panelContactanos = data.allStrapiPanelContactanos.edges
  console.log('panelContactanos: ', panelContactanos)


  const ContainerPages = styled('section')({
    margin: '-50px auto 0 auto',
    paddingTop: '2rem',
    position: 'relative',
    zIndex: 3,
    color: '#7c7b7b',
    backgroundColor: "white",
    borderRadius: '6px',
    width: '85%',
    boxShadow: '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    "@media (max-width: 1500px)": {
      width: '95%',
      // paddingRight:'100px',
      // paddingLeft:'100px',
    },
    "@media (max-width: 768px)": {
      width: '95%',
      // paddingRight:'20px',
      // paddingLeft:'20px',
      margin: '-20px auto 0 auto',
    },
    "@media (max-width: 600px)": {
      width: '92%',
      margin: '-30px auto 0 auto',
    },
  })

  const Separator = styled('div')({
    marginTop: '90px',
    backgroundColor: 'white',
    "@media (max-width: 470px)": {
      marginTop: '70px'
    },
  })

  {/* nuevo arreglo ordenado de panel informativo */ }
  const sortedArray = newArrayPanelInformativo.sort((a, b) => a.orden - b.orden)



  return (
    <LandingPage pageTitle={" Productos "}>
      <Separator>
        <PanelBanner panelBanners={panelBanners} panelPaginaGenerales={panelPaginaGenerales} />
        <ContainerPages>
          {sortedArray.length > 0 && <PanelInformativo panelInfomativoData={sortedArray[0]} />}
          <PanelBeneficios panelBeneficios={panelBeneficios} backg={true} />
          {
            sortedArray.length > 1
            &&
            sortedArray.slice(1).map((item, i) => {
              return (
                <PanelInformativo key={i} panelInfomativoData={item} backg={i % 2 === 0 ? false : true} />
              )
            })
          }
          <PreguntasFrecuentes listOfFaqs={panelPreguntaFrecuentes} />
        </ContainerPages>
        <Contactanos panelContactanos={panelContactanos} />
      </Separator>
      <ButtonUpFLoat />
    </LandingPage>
  )
}

export const query = graphql`
  query ($id: String!) {
    allStrapiPanelBanners: allStrapiPanelBanner(
      filter: { codigo_producto: { codigo_producto: { eq: $id } } }
    ) {
      edges {
        node {
          strapi_id
          nombre_banner
          texto_principal
          texto_secundario
          texto_terciario
          imagen_desktop {
            localFile {
              url
            }
          }
          imagen_mobile {
            localFile {
              url
            }
          }
          imagen_table {
            localFile {
              url
            }
          }
        }
      }
    }

    allStrapiPanelBeneficios: allStrapiPanelBeneficio(
      filter: { codigo_producto: { codigo_producto: { eq: $id } } }
    ) {
      edges {
        node {
          titulo
          titulo_principal
          strapi_id
          orden
          parrafo {
            data {
              parrafo
            }
          }
          imagen {
            localFile {
              url
            }
          }
        }
      }
    }
    allStrapiPanelInformativoImagenes: allStrapiPanelInformativoImagen(
      filter: { codigo_producto: { codigo_producto: { eq: $id } } }
    ) {
      edges {
        node {
          nombre_producto
          codigo_panel_informativo
          posicion_imagen
          orden
          imagen {
            localFile {
              url
            }
          }
        }
      }
    }
    allStrapiPanelInformativoTextos: allStrapiPanelInformativoTexto(
      filter: { codigo_producto: { codigo_producto: { eq: $id } } }
    ) {
      edges {
        node {
          texto          
          parrafo {
            data {
              parrafo
            }
          }
          strapi_id
          codigo_panel_informativo_imagen {
            codigo_panel_informativo
          }
        }
      }
    }

    allStrapiPanelPreguntaFrecuentes: allStrapiPanelPreguntaFrecuente(
      filter: { codigo_producto: { codigo_producto: { eq: $id } } }
    ) {
      edges {
        node {
          titulo_principal
          pregunta
          respuesta {
            data {
              respuesta
            }
          }
          orden
        }
      }
    }
    allStrapiPanelContactanos: allStrapiPanelContactano(
      filter: { codigo_producto: { codigo_producto: { eq: $id } } }
    ) {
      edges {
        node {
          texto
          imagen {
            localFile {
              url
            }
          }
          banner_generico
          banner_formulario
          banner_boton_accion
        }
      }
    }
    allStrapiPaginaGenerales: allStrapiPaginaGeneral(
      filter: { codigo_producto: { eq: $id } }
    ) {
      edges {
        node {
          banner_boton_accion
          banner_formulario
          banner_generico
          nombre_producto
          codigo_producto
        }
      }
    }
  }
`
