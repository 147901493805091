import React, { useState, useEffect } from 'react'
import { H2 } from '../constants/constants'
import { CleanText } from '../Functions/CleanText'
import { styled } from '@mui/material';



const Section = styled('section')({
  padding: '0 10rem', // Padding por defecto para pantallas grandes
  // Pantallas medianas: 1024px o menos
  H2: {
    textAlign: 'center',
    marginBottom: '16px',
    marginTop: "64px"
  },
  '@media (max-width: 1024px)': {
    padding: '0 40px',  // Ajuste de padding para pantallas de hasta 1024px
  },

  // Pantallas pequeñas: 800px o menos
  '@media (max-width: 800px)': {
    padding: '0 40px',
  },
  // Pantallas móviles pequeñas: 600px o menos
  "@media (max-width: 600px)": {
    padding: '20px 40px',
    H2: {
      marginTop: "32px"
    },
  },
});

const ContainerPolizas = styled('article')({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  '@media (max-width: 1024px)': {

  },

  // Pantallas pequeñas: 800px o menos
  '@media (max-width: 800px)': {

  },
  // Pantallas móviles pequeñas: 600px o menos
  "@media (max-width: 600px)": {
    gap: '10px',
  },
});

const Poliza = styled('div')({
  display: 'grid',
  border: '1px solid gray',
  padding: '20px',
  borderRadius: '10px',
  gridTemplateColumns: '150px 1fr',
  gap: '20px',
  marginBottom: '10px',
  alignItems: 'center',
  "@media (max-width: 600px)": {
    gridTemplateColumns: '1fr', // Cambia a una sola columna en pantallas pequeñas
    justifyContent: 'center',
    textAlign: 'center',         // Centra el texto en el contenido
  },
});


const Boton = styled('button')({
  background: '#ffa400',
  color: 'white',
  borderRadius: '20px',
  padding: '10px 20px',
  border: 'none',
  cursor: 'pointer',
  '&:hover':
  {
    background: '#e59300'
  }
})

const Information = styled('div')({
  padding: '10px',
  h3: {
    paddingBottom: '10px',
    margin: 0,
    fontWeight: 500
  },
  p: {
    textAlign: 'justify'
  },
  "@media (max-width: 600px)": {
    padding: '10px',
    h3: {
      textAlign: 'center',
    },
  },
})

export const SeccionPoliza = ({ seccionPoliza, title }) => {

  return (
    <Section>
      <H2>Nuestras pólizas de seguros {title}</H2>
      <ContainerPolizas>
        {
          seccionPoliza.map((element, i) => {
            const { nombre_poliza, imagen_mobile, imagen_tablet, imagen_desktop } = element.node
            const URL = element.node?.url
            const parrafo = CleanText(element.node?.parrafo.data.parrafo)
            return (
              <Poliza key={i}>
                <img src={imagen_desktop?.localFile.url}
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                  }}
                />
                <Information>
                  <h4>{nombre_poliza}</h4>
                  <p>{parrafo}</p>
                  {URL && (
                    <Boton onClick={() => window.location.assign(URL)}>Ver información</Boton>
                  )}
                  
                </Information>
              </Poliza>
            )
          })

        }
      </ContainerPolizas>
    </Section>
  )
}
