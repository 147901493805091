import React from "react";
import { styled } from '@mui/material';

const Image = styled('img')({ width: '120px', height: '120px', borderRadius:'50%' , objectFit:'cover', marginBottom:'5px'})

const SectionCard = styled('section')({
    display: 'flex', 
    width:'250px',
    flexDirection: 'column', 
    textAlign:'start',
    justifyContent:'center',
    alignItems: 'center', 
    textAlign: 'center',
    // fontSize:'8px',
    p:{
        width:'100%',
        // textAlign:'start',
        // marginTop: '8px'
    }
})

const H3 = styled('h3')({
    fontWeight:'bold',
    fontSize:'1.0rem',
    width:'80%',
    textAlign:'center',
    '@media (max-width: 768px)':{
        fontSize: '1.2rem',
    }
})


const alternativeText= 'El párrafo generalmente lo definimos como un conjunto de oraciones escritas que comparten un tema y un contexto comunicativo, por lo que las escribimos en un orden, es decir, en secuencia. El párrafo también es considerado una de las unidades en que se puede dividir un texto escrito.'

export const CardCarousel = ({data})=>{
    const {title,paragraph,imgUrl} = data
    return (
        <SectionCard>
            <Image src={imgUrl} alt={`Imagen de ${imgUrl}`} />
            <H3>{title}</H3>
            <p>{paragraph}</p>
        </SectionCard>
    )
}