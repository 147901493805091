import React, { useState, useEffect } from "react";
import { styled } from '@mui/material';
import { FormularioCotizar } from "./FormularioCotizar";
import { ButtonCotizar } from "./ButtonCotizar";

const SectionPrincipal = styled("section")({
    background: "#ffa400",
    display: "flex",
    color: "white",
    padding: "3rem",
    paddingTop: '6rem',
    paddingBottom: '5rem',
    justifyContent: "space-around",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    gap: "10px",
    "@media (max-width: 768px)": {
        flexWrap: "wrap",
        textAlign: "center",
        padding: "50px",
        justifyContent: "center",
        alignItems: "center",
    },
    "@media (max-width: 600px)": {
        h2: {
            fontSize: '1rem',
        },
        h1: {
            fontSize: '1.5rem',
        },
    },
});

const CotizadorForm = styled('div')({
    flexShrink: '0',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center'
});

const Article = styled('article')({
    h2: {
        margin: '0',
        fontSize: '1.2rem',
        fontWeight: 'bold'
    },
    h1: {
        margin: '0',
        fontSize: '2.8rem',
        fontWeight: 'bold'
    },
    "@media (max-width: 768px)": {
        h2: {
            fontSize: '1rem',
        },
    },
    "@media (max-width: 600px)": {
        h2: {
            fontSize: '1rem',
        },
        h1: {
            fontSize: '1.5rem',
        },
    },
});

export const PanelBanner = ({ panelBanners, panelPaginaGenerales }) => {
    console.log("panelBanners: ", panelBanners)
    const { texto_principal = '', texto_secundario = '', texto_terciario = '' } = panelBanners[0]?.node || {};
    const imagen_desktop = panelBanners[0]?.node.imagen_desktop?.localFile?.url;
    const imagen_table = panelBanners[0]?.node.imagen_table?.localFile?.url;
    const imagen_mobile = panelBanners[0]?.node.imagen_mobile?.localFile?.url;
    let { banner_boton_accion = false, banner_formulario = false, banner_generico = false } = panelPaginaGenerales[0]?.node || {};

    const [backgroundImage, setBackgroundImage] = useState(imagen_desktop);
    const [marginTopTitle, setMarginTopTitle] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 600) {
                setBackgroundImage(imagen_mobile);
                setMarginTopTitle(0)
            } else if (window.innerWidth < 769) {
                setBackgroundImage(imagen_table);
                setMarginTopTitle(0)
            } else {
                setBackgroundImage(imagen_desktop);
                setMarginTopTitle(120)
            }
        };

        // Llama a handleResize al montar el componente para establecer la imagen correcta
        handleResize();

        // Escucha cambios en el tamaño de la ventana
        window.addEventListener("resize", handleResize);

        // Limpia el evento cuando el componente se desmonte
        return () => window.removeEventListener("resize", handleResize);
    }, [imagen_desktop, imagen_table, imagen_mobile]);

    const RenderBanner = () => {
        if (banner_formulario) {
            return (
                <CotizadorForm>
                    <FormularioCotizar panelPaginaGenerales={panelPaginaGenerales} />
                </CotizadorForm>
            );
        }

        if (banner_boton_accion) {
            return (
                <CotizadorForm>
                    <ButtonCotizar />
                </CotizadorForm>
            );
        }
        return null;
    };

    return (
        <SectionPrincipal style={{
            backgroundImage: `url(${backgroundImage})`,
        }}>
            <Article>
                <h1 style={{marginTop:marginTopTitle}}>{texto_principal}</h1>
                <h2>{texto_secundario}</h2>
                {texto_terciario && <p>{texto_terciario}</p>}
            </Article>
            {RenderBanner()}
        </SectionPrincipal>
    );
};
