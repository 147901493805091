import { styled } from "@mui/material"

export const H2 = styled('h2')({
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '1.6rem',
    "@media (max-width: 768px)": {
        fontSize: '1.7rem'
    },
})


export const COLOR_FONDO = {
    color: '#f9f9f9'
}

export const TAMANIO_IMG_PANEL_INFORMATIVO = {
    width: '400px',
    height: '400px'
}
