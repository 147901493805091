import React from "react"
import { FormControl, Input, Box, Typography, Icon } from "@mui/material"
import Button from "components/material-dashboard-pro-react/components/CustomButtons/Button"

export const ButtonCotizar = () => {
  //para luego procesar los datos del formulario
  const handleForm = async (e) => {
    e.preventDefault()
    // Recoger los valores de los campos usando ref
  }

  return (
    <Box
      component="form"
      onSubmit={handleForm} // Cambia el evento de click del botón por onSubmit en el formulario
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px", // Espacio entre los campos
        background: "#f5f5f5",
        padding: "1rem",
        width: "320px", // Ancho fijo
        height: "180px", // Altura fija
        maxWidth: "100%", // Asegura que no se desborde en pantallas pequeñas
        margin: "auto", // Centra el formulario
        justifyContent: "space-between", // Distribuye espacio entre los elementos
      }}
    >
      {/* Título del formulario y subtítulo en el mismo Typography */}
      <Typography
        variant="h6"
        component="h2"
        sx={{
          textAlign: "center",
          color: "#fc2d23",
          fontSize: "0.9rem",
          margin: "0",
          lineHeight: "1.5rem", // Espaciado entre líneas
          borderBottom: "1px solid red",
        }}
      >
        Cotiza tu póliza de forma rápida y sencilla
        <br />
        <span style={{ fontWeight: "bold", fontSize: "0.8rem" }}>Personas</span>
      </Typography>

      {/* Botón Cotizar */}
      <Button color="primary" type="submit" fullWidth sx={{ mt: 1 }}>
        <Icon>send</Icon> Cotizar
      </Button>
    </Box>
  )
}
