import { CleanText } from "./CleanText";
const variable = `<p style="margin-left:0px;">Los párrafos cortos son aquellos párrafos que tienen entre tres y seis líneas. Si bien existen párrafos más extensos, que pueden llegar hasta las veinte líneas, lo recomendable es que un párrafo no tenga más de cuatro o cinco oraciones.</p><p style="margin-left:0px;">Un párrafo es una unidad de un texto compuesta por una o varias oraciones, que comienza con una mayúscula y que termina con un punto y aparte. Los textos se organizan de manera tal que cada párrafo trata sobre una idea central. Generalmente, la primera oración de cada párrafo suele explicitar cuál es el punto principal que se desarrollará.</p>`;

export const SeparateParagraphs = (htmlString) => {
  // Dividir el string HTML en fragmentos de párrafos usando la etiqueta <p> como delimitador
  const paragraphs = htmlString.split(/<\/?p[^>]*>/).filter(paragraph => paragraph.trim() !== "");
  // Limpiar cada párrafo usando la función CleanText
  return paragraphs.map(paragraph => CleanText(paragraph));
};

