import React from 'react'
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material';
import { useState } from 'react';
import '../css/PreguntasFrecuentes.css';
import { CleanText } from "../Functions/CleanText"
import '../css/PreguntasFrecuentes.css'
import { H2 } from '../constants/constants';

const SectionFaqs = styled('section')({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '20px 10rem 5rem 10rem', // Padding por defecto para pantallas grandes
    "@media (max-width: 1024px)": {
        padding: '20px 40px',
    },
});


export const PreguntasFrecuentes = ( {listOfFaqs} ) => {

    //verificar para hacer un custom hook para ordenar
    const copyListOfFaqs = structuredClone(listOfFaqs);
    const orderedList = copyListOfFaqs.sort((a, b) => a.node.orden - b.node.orden);

    // console.log('Esta es la lista ordenada', orderedList)
    
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
        <H2>Preguntas Frecuentes</H2>
        <SectionFaqs className="container-faqs">
            {orderedList.map((faq, i) => {

                const pregunta= faq.node.pregunta;
                const respuesta = faq.node.respuesta.data.respuesta;
                const newRespuesta = CleanText(respuesta);
                const isCurrentlyExpanded = expanded === i;

                return (
                    <Accordion
                        key={i}
                        expanded={expanded === i}
                        onChange={handleChange(i)}
                        sx={{
                            borderRadius: '16px',
                            border: 'none',
                            backgroundColor: 'transparent',
                            color: 'gray',
                            boxShadow:'none',
                            // Eliminar el margen predeterminado
                            '&:not(:last-child)': {
                                marginBottom: 0,
                            },
                            '&:before': { display: 'none' } 
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${i}-content`}
                            id={`panel${i}-header`}
                            sx={{
                                backgroundColor: '#e8e8e8',
                                borderRadius: isCurrentlyExpanded ? '16px 16px 0 0' : '16px',
                                border: 'none',
                                padding: '0 16px',
                                marginTop:"5px"
                            }}
                        >
                            <Typography sx={{ fontSize: '1.2rem', fontWeight: 'bolder',  }}>
                                {pregunta}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                backgroundColor: '#ffffff',
                                paddingTop: '1em',
                                borderRadius: '0 0 16px 16px',
                                padding: '16px',
                                textAlign:"justify",
                            }}
                        >
                            <Typography sx={{ border: 'none', fontSize: '1rem',paddingRight:"20px" }}>
                                    {newRespuesta}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </SectionFaqs>
        </>
    );

};

