
import React,{useEffect,useState} from "react"
import { styled } from '@mui/material'

const SectionPrincipal = styled("section")({
    // height: "600px",
    // background: "#ffa400",
    display: "flex",
    color: "white",
    paddingTop:'6rem',
    paddingBottom:'5rem',
    justifyContent: "flex-start", // Empuja el contenido a los extremos
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    gap: "10px",
    "@media (max-width: 768px)": {
        flexWrap: "wrap",
        textAlign: "center",
        padding: "50px",
        justifyContent: "center",
        alignItems: "center",
        // height:'auto'
    },
    "@media (max-width: 600px)": {
        h1:{
            fontSize:'1rem',
         },
         h3:{
             fontSize:'1.5rem'
         },
    },
});



const Article = styled('article')({
    // display:'flex',
    // flexDirection: "column", 
    // gap:'10px',
    justifyContent:'center',
    // lineHeight: '65px',
    width: '85%',
    margin: '0 auto',
    h2:{
    lineHeight: '65px',
        margin:'0',
       fontSize:'1.8rem',
       fontWeight:'bold'
    },
    h1:{
        // lineHeight: '65px',
        margin:'0',
        fontSize:'2.8rem',
        fontWeight:'bold',
        "@media (min-width: 1600px)": {
        fontSize:'3.2rem'
    }
    },
    p:{
        lineHeight: '65px',
        fontSize:'1.3rem',
        
    },
    "@media (max-width: 768px)": {
        width: '100%',
        
        h1:{
            lineHeight: '45px',
            fontSize:'2.5rem',
            width:'100%',
         },
         h2:{
            lineHeight: '45px',
             fontSize:'1.6rem'
         },
    },
    "@media (max-width: 600px)": {
        paddingTop: '50px',
        paddingBottom: '50px',

        h1:{
            lineHeight: '45px',
            fontSize:'1.8rem',
            width:'100%',
         },
         h2:{
            lineHeight: '45px',
             fontSize:'1.4rem'
         },
         p:{
            lineHeight: '45px',
        }
    },
    
})

export const PanelBanner = ({panelBanners})=>{
    
    const {texto_principal='',texto_secundario='',texto_terciario=''} = panelBanners[0]?.node || {}
    const imagen_desktop = panelBanners[0].node.imagen_desktop.localFile.url;     
    const imagen_table = panelBanners[0].node.imagen_tablet.localFile.url
    const imagen_mobile = panelBanners[0].node.imagen_mobile.localFile.url
    const [backgroundImage, setBackgroundImage] = useState(imagen_desktop);


useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 600) {
                setBackgroundImage(imagen_mobile);
            } else if (window.innerWidth < 769) {
                setBackgroundImage(imagen_table);
            } else {
                setBackgroundImage(imagen_desktop);
            }
        };

        // Llama a handleResize al montar el componente para establecer la imagen correcta
        handleResize();

        // Escucha cambios en el tamaño de la ventana
        window.addEventListener("resize", handleResize);

        // Limpia el evento cuando el componente se desmonte
        return () => window.removeEventListener("resize", handleResize);
    }, [imagen_desktop, imagen_table, imagen_mobile]);


    // let {banner_boton_accion=false,banner_formulario=false,banner_generico=false} = panelPaginaGenerales[0]?.node || {}
    
    // const RenderBanner = ()=>{

    //     // banner_formulario = false;
    //     // banner_boton_accion = true;
    //     // banner_generico = true;
    //     if(banner_formulario){

    //         return (
    //             <CotizadorForm>
    //                 <FormularioCotizar/>
    //             </CotizadorForm>
    //         )
    //     }

    //     if(banner_boton_accion){
    //         return(
    //             <CotizadorForm>
    //                 <ButtonCotizar/>
    //             </CotizadorForm>
    //         )
    //     }
    //     return null
    // }
    
    // imgUrl = 

    return(

        <SectionPrincipal style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }}>
                    <Article >
                        <h1>{texto_principal}</h1>
                        <h2>{texto_secundario}</h2>
                        { texto_terciario &&  <p>{texto_terciario}</p>}
                    </Article>
                    {/* acá hacemos la comparación si queremos traer un formulario o solamente un botón */}
                    {/* {RenderBanner()} */}
            </SectionPrincipal>
    )
}
